import moment from 'moment'
import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppActions, Callbacks } from '../app-actions'
import {
  AppState,
  BarristerProfile,
  LawyerProfile,
  LawyerSubscription,
  RequestTypes,
} from '../state-type'

type UseLawyersProfile = {
  lawyersProfile: AppState['lawyersProfile']
  lawyersProfileLoading: AppState['lawyersProfileLoading']
  lawyersProfileSaving: AppState['lawyersProfileSaving']
  subscriptionCode?: string
  lawyersProfileError: AppState['lawyersProfileError']
  getLawyersProfile: (
    data: RequestTypes['getLawyersProfile'],
    callbacks?: Callbacks,
  ) => void
  createLawyersProfile: (
    data: RequestTypes['getLawyersProfile'],
    callbacks?: Callbacks,
  ) => void
  updateLawyersProfile: (
    data: RequestTypes['updateLawyersProfile'],
    callbacks?: Callbacks,
  ) => void
  activeSubscription: LawyerSubscription
  isPremium: boolean
  isCancelled: boolean
}

export default function useLawyersProfile(): UseLawyersProfile {
  const {
    lawyersProfile,
    lawyersProfileLoading,
    lawyersProfileSaving,
    lawyersProfileError,
    activeSubscription,
    isCancelled,
    isPremium,
  } = useSelector((state: AppState) => {
    const activeSubscriptions = state.profile?.subscriptions
      ? state.profile?.subscriptions.filter((v) => {
          return (
            moment(v.expiry_date).valueOf() > new Date().valueOf() &&
            v.subscription_type === 'STANDARD'
          )
        })
      : []
    return {
      lawyersProfile: state.lawyersProfile,
      lawyersProfileLoading: state.lawyersProfileLoading,
      lawyersProfileSaving: state.lawyersProfileSaving,
      lawyersProfileError: state.lawyersProfileError,
      activeSubscription:
        activeSubscriptions?.[activeSubscriptions?.length - 1],
      isPremium: state.profile?.premium,
      isCancelled:
        activeSubscriptions?.[activeSubscriptions?.length - 1]?.cancelled,
    }
  })
  const dispatch = useDispatch()

  const getLawyersProfile = useCallback(
    (data: RequestTypes['getLawyersProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.getLawyersProfile(data, callbacks))
    },
    [dispatch],
  )
  const createLawyersProfile = useCallback(
    (data: RequestTypes['createLawyersProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.createLawyersProfile(data, callbacks))
    },
    [dispatch],
  )
  const updateLawyersProfile = useCallback(
    (data: RequestTypes['updateLawyersProfile'], callbacks?: Callbacks) => {
      return dispatch(AppActions.updateLawyersProfile(data, callbacks))
    },
    [dispatch],
  )

  return {
    lawyersProfile,
    subscriptionCode:
      (lawyersProfile as LawyerProfile)?.lawyer_subscription_code?.[0]?.code ||
      (lawyersProfile as BarristerProfile)?.barrister_subscription_code?.[0]
        ?.code,
    lawyersProfileLoading,
    lawyersProfileSaving,
    lawyersProfileError,
    getLawyersProfile,
    createLawyersProfile,
    updateLawyersProfile,
    activeSubscription,
    isCancelled,
    isPremium,
  }
}
