import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  typography: {
    fontFamily: '"DM Sans", sans-serif',
  },
  palette: {
    primary: {
      main: '#020251',
    },
    secondary: {
      main: '#37A2BA',
    },
    tertiary: {
      main: '#8DD8EB',
    },
    quaternary: {
      main: '#ff0000',
    },
  },
})

export default theme
