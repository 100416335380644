import { FC, ReactNode, useCallback, useEffect, useState } from 'react'
import Cognito from '../common/cognito'
import useProfile from '../common/providers/useProfile'
import Constants from '../common/utils/constants'
import Router, { useRouter } from 'next/router'
import useLawyersProfile from '../common/providers/useLawyersProfile'
import { FullScreenLoader } from './FullScreenLoader'
import { encodeAuthState } from 'common/utils/auth'

type AuthProviderProps = {
  children: ReactNode
}

const AuthProvider: FC = ({ children }: AuthProviderProps) => {
  const [isLoading, setIsLoding] = useState<boolean>(true)
  const { getProfile, profileError } = useProfile()
  const router = useRouter()
  const { getLawyersProfile } = useLawyersProfile()

  const handleRedirects = useCallback(async () => {
    const res = await Cognito.getSession()
    const idToken = res.getIdToken()

    if (!idToken) {
      throw new Error('No ID token')
    }

    const sessionData = idToken.payload

    if (!sessionData) {
      router.replace('/login')

      return
    }

    if (!sessionData.email_verified) {
      router.push(
        `/verify-email?state=${encodeAuthState({
          email: sessionData.email,
        })}`,
      )

      return
    }

    getProfile({
      onSuccess: () => {
        getLawyersProfile(
          {},
          {
            onSuccess: () => {
              if (!router.pathname || router.pathname === '/') {
                router.replace('/account')
              }

              setIsLoding(false)
            },
            onError: async () => {
              setIsLoding(false)

              if (
                !Router.pathname.includes('mfa') &&
                !Router.pathname.includes('setup') &&
                !Router.pathname.includes('create-profile') &&
                !Router.pathname.includes('edit-profile')
              ) {
                const cognitoUser = await Cognito.currentAuthenticatedUser()

                if (cognitoUser.preferredMFA === 'NOMFA') {
                  router.push('/mfa')
                } else {
                  router.push('/mfa/verify')
                }
              }
            },
          },
        )
      },
    })
  }, [getLawyersProfile, getProfile, router])

  const checkSession = useCallback(async () => {
    try {
      await handleRedirects()
    } catch (err) {
      console.error(err)

      if (!Constants.authRoutes.includes(router.pathname)) {
        router.replace('/login')
      }
    }

    setIsLoding(false)
  }, [handleRedirects, router])

  useEffect(() => {
    checkSession()
  }, [checkSession])

  useEffect(() => {
    if (profileError && !Constants.authRoutes.includes(router.pathname)) {
      router.replace('/login')
    }
  }, [profileError, router])

  return <>{isLoading ? <FullScreenLoader delay={500} /> : children}</>
}

export default AuthProvider
