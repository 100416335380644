import classNames from 'classnames'
import { Navbar } from '../common/components/Nav/Navbar'
import { useRouter } from 'next/router'
import { Button, Toolbar, Typography, Box } from '@mui/material'
import { Home, Gavel, AccountBalance, Person } from '@mui/icons-material' // Placeholder icons
import Flag from 'react-world-flags'
import HelpOutlineIcon from '@mui/icons-material/HelpOutline'

const EXCLUDED_ROUTES = ['/', ...Constants.authRoutes]
const DASHBOARD_ROUTES = ['/account']

type NavBarProps = {
  isDashboard?: boolean
  title?: string
  setIsSidebarOpen?: (isOpen: boolean) => void
  className?: string
}

const NavBar = ({
  title,
  setIsSidebarOpen,
  className,
  isDashboard,
}: NavBarProps) => {
  const { asPath } = useRouter()
  const pathname = asPath.split('?')[0]

  if (EXCLUDED_ROUTES.includes(pathname)) {
    return null
  }

  if (DASHBOARD_ROUTES.includes(pathname) && !isDashboard) {
    return null
  }

  return (
    <Toolbar
      className='d-flex w-100 align-items-center'
      sx={{ 'height': '100px' }}
    >
      <Box
        className='mr-auto d-flex align-items-center'
        sx={{ paddingRight: '40px' }}
      >
        <img
          src='/images/lu-logo.svg'
          alt='Legal Utopia'
          style={{
            height: 40,
            paddingRight: '9px',
          }}
        />
        <Typography
          color='primary'
          sx={{
            'font-size': '24px',
            'fontWeight': '900',
            'textOverflow': 'ellipsis',
          }}
        >
          Legal Utopia
        </Typography>
      </Box>

      <Box className='flex-grow-1'>
        <Button>Our App</Button>
        <Button>Discover Law</Button>
        <Button>Legal Help</Button>
        <Button>For Lawyers</Button>
        <Button>Pricing</Button>
        <Button>Who We Are</Button>
        <Button>Contact Us</Button>
      </Box>

      <Box className='ml-auto d-flex align-items-center'>
        <Box className='d-flex align-items-center'>
          <HelpOutlineIcon size='16px' sx={{ marginRight: '5px' }} />
          <Typography
            color='primary'
            sx={{
              'font-size': '16px',
              'fontWeight': '500',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              marginRight: '20px',
            }}
          >
            Contact Support
          </Typography>
        </Box>

        <Flag
          code='GB'
          style={{ height: '32px', width: 'auto', marginRight: '20px' }}
        />

        <Navbar
          isSlimDropdown
          className={classNames(
            'bg-white xl:bg-transparent xl:border-none',
            className,
          )}
          dropdownOptions={[
            {
              isLink: true,
              label: 'Settings',
              value: '/settings',
              icon: '/images/nav-dropdown/cog.svg',
            },
          ]}
        >
          <h1 className='text-3xl	text-lue-blue-700 mb-0 font-medium hidden xl:block'>
            {title}
          </h1>
          <button
            className='xl:hidden'
            onClick={() => {
              setIsSidebarOpen?.(true)
            }}
          >
            <img src='/images/sidebar/hamburger.svg' />
          </button>
        </Navbar>
      </Box>
    </Toolbar>
  )
}

export default NavBar
