import cn from 'classnames'
import { useEffect, useState } from 'react'

type FullScreenLoaderProps = {
  delay?: number
}

export const FullScreenLoader = ({ delay }: FullScreenLoaderProps) => {
  const [show, setShow] = useState<boolean>(!delay)

  useEffect(() => {
    if (!delay) {
      return () => {}
    }

    const timer = setTimeout(() => {
      setShow(true)
    }, delay)

    return () => {
      clearTimeout(timer)
      setShow(true)
    }
  }, [delay])

  return (
    <div
      className={cn(
        'flex h-screen w-screen items-center justify-center transition-opacity duration-500',
        show ? 'opacity-100' : 'opacity-0',
      )}
    >
      <Loader />
    </div>
  )
}
