import React, { ReactNode, useRef, useState } from 'react'
import cn from 'classnames'
import { ButtonText } from 'components/base/forms/Button'
export const triggerNativeEventFor = (elm, { event, ...valueObj }) => {
  if (!(elm instanceof Element)) {
    throw new Error(`Expected an Element but received ${elm} instead!`)
  }

  const [prop, value] = Object.entries(valueObj)[0] ?? []
  const desc = Object.getOwnPropertyDescriptor(elm.__proto__, prop)

  desc?.set?.call(elm, value)
  elm.dispatchEvent(new Event(event, { bubbles: true }))
}
interface Input {
  textarea?: boolean
  isValid?: boolean
  placeholder?: string
  inputClassName?: string
  name?: string
  label?: string
  icon?: string
  type?: string
  textButton?: string
  className?: string
  errorMessage?: string
  iconColour?: string
  touched?: boolean
  value?: string
  onIconClick?: () => void
  deleteLabel?: React.ReactNode
  onChange?: (e: React.ChangeEvent) => void
  onFocus?: (e: React.FocusEvent) => void
  prepend?: ReactNode
  onBlur?: (e: React.FocusEvent) => void
  onKeyDown?: (e: React.KeyboardEvent) => void
  disabled?: boolean
}

const Input: React.FC<Input> = ({
  children,
  className,
  deleteLabel,
  disabled,
  errorMessage,
  icon,
  iconColour,
  inputClassName,
  isValid = true,
  label,
  name,
  onBlur,
  onFocus,
  onIconClick,
  onKeyDown,
  prepend,
  placeholder,
  textButton,
  textarea,
  touched,
  type,
  value,
  ...rest
}) => {
  const [shouldValidate, setShouldValidate] = useState(false)
  const [isFocused, setIsFocused] = useState(false)
  const ref = useRef<HTMLInputElement | HTMLTextAreaElement>()
  const focusHandler = (e: React.FocusEvent) => {
    setIsFocused(true)
    onFocus && onFocus(e)
  }

  // Is it element important? Should I use UseRef hook?
  // const focus = () => {
  //   this.input.focus();
  // };

  const _onKeyDown = (e: React.KeyboardEvent) => {
    if (Utils.keys.isEscape(e)) {
      ref.current.blur()
    }
    onKeyDown && onKeyDown(e)
  }

  const blur = (e: React.FocusEvent) => {
    if (type === 'url') {
      let newValue = value
      if (newValue) {
        if (!newValue.includes('www.')) {
          newValue = 'www.' + newValue.replace(/https?:\/\//, '')
        }
        if (!newValue.includes('http://') && !newValue.includes('https://')) {
          newValue = 'https://' + newValue
        }
      }
      if (newValue !== value) {
        triggerNativeEventFor(ref.current, {
          event: 'input',
          value: newValue,
        })
        return
      }
    }
    setShouldValidate(true)
    setIsFocused(false)
    onBlur && onBlur(e)
  }

  const classNameHandler = cn(
    {
      'input-container': true,
      focused: isFocused,
      'input-container-prepend': prepend,
      invalid: (shouldValidate || touched) && !isValid,
    },
    className,
  )

  const combinedInputClassName = cn(
    { input: true, error: !!errorMessage },
    inputClassName,
  )
  const [showPassword, setShowPassword] = useState<boolean>(false)
  return (
    <div data-test={`${name}-container`} className={classNameHandler}>
      {prepend && <span className='prepend-icon'>{prepend}</span>}

      {textarea ? (
        <>
          {label && <label htmlFor={name}>{label}</label>}
          <textarea
            name={name}
            placeholder={placeholder}
            {...rest}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={_onKeyDown}
            onBlur={blur}
            value={value}
            className={combinedInputClassName}
          />
        </>
      ) : (
        <>
          <div className='row'>
            <div className='col'>
              {!!label && <label htmlFor={name}>{label}</label>}
            </div>
            <div className='text-right mr-3'>
              {!!textButton && (
                <ButtonText className='btn__small'>{textButton}</ButtonText>
              )}
            </div>
          </div>
          <input
            disabled={disabled}
            name={name}
            type={
              type === 'password' && showPassword
                ? ''
                : type === 'url'
                ? 'text'
                : type
            }
            {...rest}
            // @ts-ignore
            ref={ref}
            onFocus={focusHandler}
            onKeyDown={_onKeyDown}
            onBlur={blur}
            value={value}
            placeholder={placeholder}
            className={combinedInputClassName}
          />
        </>
      )}
      {children && children}
    </div>
  )
}

global.Input = Input
Input.displayName = 'Input'
export default Input
