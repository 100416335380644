import React from 'react'
import Input from './Input'
import Tooltip from '../../Tooltip'

export interface InputGroup {
  inputProps?: {
    maxLength?: number
    name?: string
    id?: string
    error?: string
  }
  className?: string
  title?: string
  component?: React.ReactNode
  textarea?: boolean
  isValid?: boolean
  readOnly?: boolean
  onBlur?: (e: React.FocusEvent) => void
  disabled?: string
  errorMessage?: string
  tooltip?: string
  name?: string
  prepend?: boolean
  value?: string
  defaultValue?: string
  onChange?: (e: any) => void
  type?: string
  placeholder?: string
}

const InputGroup: React.FC<InputGroup> = (
  {
    name,
    onBlur,
    errorMessage,
    inputProps = {},
    isValid,
    readOnly,
    className,
    title,
    component,
    textarea,
    disabled,
    value,
    tooltip,
    defaultValue,
    onChange,
    type,
    placeholder,
    prepend,
  },
  props,
) => {
  const id = inputProps.id || inputProps.name || Utils.GUID()
  const label = (
    <label htmlFor={id} className='cols-sm-2 control-label flex items-center'>
      {title}{' '}
      {readOnly && (
        <span style={{ fontSize: 16 }} className='text-muted ml-1'>
          (Read Only)
        </span>
      )}
      {!!tooltip && <img src='/images/info-icon.svg' className='ml-2' />}
    </label>
  )
  return (
    <div className={`form-group text-left ${className}` || ''}>
      {tooltip ? <Tooltip title={tooltip}>{label}</Tooltip> : label}

      {inputProps && inputProps.error && (
        <span>
          <span> - </span>
          <span
            id={inputProps.name ? `${inputProps.name}-error` : ''}
            className='text-danger'
          >
            {inputProps.error}
          </span>
        </span>
      )}

      <div>
        {component ? (
          component
        ) : (
          <div>
            <Input
              // ref={(c) => (this.input = c)}
              name={name}
              textarea={textarea}
              onBlur={onBlur}
              prepend={prepend}
              errorMessage={errorMessage}
              readOnly={readOnly}
              {...inputProps}
              isValid={isValid}
              disabled={disabled}
              value={value}
              data-test={['data-test']}
              defaultValue={defaultValue}
              onChange={onChange}
              type={type || 'text'}
              id={id}
              placeholder={placeholder}
            />
          </div>
        )}
      </div>
    </div>
  )
}

global.InputGroup = InputGroup
InputGroup.displayName = 'InputGroup'
export default InputGroup
