import { SVGProps } from 'react'

export const ProfileIcon = (props: SVGProps<SVGSVGElement>) => (
 <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="20" cy="20" r="19.5" fill="white" stroke="#3232B0"/>
<circle cx="20" cy="17.5" r="6.5" fill="#14148A" stroke="#14148A" stroke-width="2"/>
<path d="M6.25 33.7336C6.36229 31.6971 6.66165 31.0528 8.14571 29.6537C9.62977 28.2546 11.43 27.4993 13.2793 27.5H26.7164C28.5681 27.4992 30.3705 28.2563 31.8557 29.6586C33.3408 31.061 33.6414 31.7103 33.75 33.75L31.25 36.25L26.25 38.75L20.0002 39.5L13.75 38.75L8.75 36.25L6.25 33.7336Z" fill="#14148A"/>
</svg>
)

