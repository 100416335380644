/**/ import ReactTooltip from 'react-tooltip'
import { FC, ReactNode, useRef } from 'react'

type ComponentType = {
  children: ReactNode
  title: string
  place?: string
}

const TheComponent: FC<ComponentType> = ({ title, place, children }) => {
  const id = useRef(Utils.GUID())
  return (
    <span className='question-tooltip'>
      {title ? (
        <span data-for={id.current} data-tip>
          {children}
        </span>
      ) : (
        <span className='ion ion-ios-help' data-for={id.current} data-tip />
      )}
      <ReactTooltip
        id={id.current}
        place={place || 'top'}
        type='dark'
        effect='solid'
      >
        <div className='flex flex-row'>
          <div className='icon--tooltip ion-ios-information-circle pr-2'></div>
          <span>{title}</span>
        </div>
      </ReactTooltip>
    </span>
  )
}

export default TheComponent
